<template>
  <div class="chat-layout">
    <chat-header :title="headerText" />

    <transition name="fade-faster" mode="out-in">
      <webchat
        v-if="displayedScreen === 'webchat'"
        :account-name="selectedAccount?.name"
        :conversation-messages="conversationMessages"
        :customer-actions="visibleCustomerActions"
        :show-customer-actions="showCustomerActions"
        :submitting-contact-form="submittingContactForm"
        :submit-contact-form-error="submitContactFormError"
        @customer-action-click="handleCustomerActionClick"
        @submit-contact-form="handleSubmitContactForm"
      />

      <mso-contact-form
        v-else-if="displayedScreen === 'mso-contact-form'"
        :company-accounts="companyData.accounts"
        :selected-account="selectedAccount"
        @shop-selected="handleMsoShopSelected"
        @question-submitted="handleMsoContactFormSubmitted"
      />
    </transition>

    <chat-footer />
  </div>
</template>

<script>
import ChatHeader from "./components/ChatHeader";
import Webchat from "./components/Webchat";
import MsoContactForm from "./components/mso-contact-form/MsoContactForm";
import ChatFooter from "./components/ChatFooter";

import { generateUniqueSlug } from "./utils";

import { INITIAL_WEBCHAT_CUSTOMER_ACTIONS } from "./config";

import CompanyService from "./services/CompanyService";
import CustomerService from "./services/CustomerService";

export default {
  name: "App",
  components: {
    ChatHeader,
    Webchat,
    MsoContactForm,
    ChatFooter,
  },
  data() {
    return {
      companyData: {
        name: "Loading...",
        accounts: [],
      },
      selectedAccount: null,

      displayedScreen: "webchat",

      conversationMessages: [],

      submittingContactForm: false,
      submitContactFormError: "",

      showCustomerActions: false,
      customerActions: INITIAL_WEBCHAT_CUSTOMER_ACTIONS,
    };
  },
  async mounted() {
    this.fetchCompanyInfo();

    this.appendInitialMessage();
  },
  computed: {
    headerText() {
      if (this.preselectAccountSlug && this.selectedAccount) {
        return this.selectedAccount.name;
      }
      return this.companyData.name;
    },
    visibleCustomerActions() {
      return this.customerActions.filter((action) => action.visible);
    },
    shopCompanySlug() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("shop_slug") || "";
    },
    preselectAccountSlug() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("preselect_account") || "";
    },
    isMSO() {
      return this.companyData.accounts.length > 1;
    },
  },
  methods: {
    async fetchCompanyInfo() {
      const [companyDataResponse, companyAccountsResponse] = await Promise.all([
        CompanyService.get.show(this.shopCompanySlug),
        CompanyService.get.accounts(this.shopCompanySlug),
      ]);

      this.companyData = {
        name: companyDataResponse.data.data.name,
        accounts: companyAccountsResponse.data.data,
      };

      if (this.companyData.accounts.length === 1) {
        this.selectedAccount = this.companyData.accounts.at(0);
        return;
      }

      // is multi-shop but has a preselected account passed from external source
      if (this.preselectAccountSlug) {
        this.selectedAccount = this.companyData.accounts.find(
          (account) => account.slug === this.preselectAccountSlug
        );
      }
    },

    async appendReceivedMessage(message) {
      const messageToAppend = {
        ...message,
        received: true,
        typing: true,
      };

      this.conversationMessages.push(messageToAppend);

      await new Promise((resolve) => setTimeout(() => resolve(true), 1000));

      messageToAppend.typing = false;
    },

    async appendInitialMessage() {
      await this.appendReceivedMessage({
        slug: "initial-message",
        content: "How can I help? ",
      });

      await new Promise((resolve) => setTimeout(() => resolve(true), 250));

      this.showCustomerActions = true;
    },

    openBookAppointmentModal() {
      window.parent.postMessage(
        { name: "jasmine.openBookAppointmentModal", payload: {} },
        "*"
      );
      window.parent.postMessage({ name: "jasmine.closeChat" }, "*");
    },

    async handleCustomerActionSelected(action) {
      this.conversationMessages.push({
        slug: generateUniqueSlug(),
        content: action.text,
      });

      this.customerActions = this.customerActions.map((customerAction) => {
        if (customerAction.slug !== action.slug) return customerAction;
        return { ...customerAction, visible: false };
      });

      await new Promise((resolve) => setTimeout(() => resolve(true), 500));

      return this.appendReceivedMessage({
        slug: generateUniqueSlug(),
        content: action.predefinedAnswer,
      });
    },

    scrollChatBodyToBottom() {
      this.$nextTick(() => {
        const chatBody = this.$el.querySelector(".chat-body");
        chatBody.scrollTop = chatBody.scrollHeight;
      });
    },

    async appendSubmitCustomerContactFormMessage() {
      await this.appendReceivedMessage({
        slug: "customer-contact-form",
        submitted: false,
      });
      this.scrollChatBodyToBottom();
    },

    async handleCustomerActionClick(action) {
      if (action.slug === "book-appointment") {
        this.openBookAppointmentModal();
        return;
      }

      if (
        action.slug === "leave-a-question" &&
        this.isMSO &&
        !this.preselectAccountSlug
      ) {
        this.displayedScreen = "mso-contact-form";
        this.customerActions = this.customerActions.map((customerAction) => {
          if (
            ["leave-a-question", "looking-around"].includes(customerAction.slug)
          ) {
            return { ...customerAction, visible: false };
          }
          return customerAction;
        });
        return;
      }

      await this.handleCustomerActionSelected(action);

      if (action.slug === "leave-a-question") {
        await this.appendSubmitCustomerContactFormMessage();
        this.showCustomerActions = false;
      }
    },

    async handleSubmitContactForm({ name, phone, messageContent }) {
      if (!this.selectedAccount) return;

      try {
        this.submitContactFormError = "";
        this.submittingContactForm = true;

        await CustomerService.post.contact(
          this.selectedAccount.slug,
          name,
          phone,
          messageContent
        );

        this.conversationMessages = this.conversationMessages.map(
          (conversationMessage) => {
            if (conversationMessage.slug === "customer-contact-form") {
              return { ...conversationMessage, submitted: true };
            }
            return conversationMessage;
          }
        );

        this.submittingContactForm = true;

        await new Promise((resolve) => setTimeout(() => resolve(true), 500));

        return this.appendReceivedMessage({
          slug: generateUniqueSlug(),
          content: `Thank you, ${name}! I let the shop know and they\'ll text you back shortly.`,
        });
      } catch (error) {
        this.submittingContactForm = false;
        if (error?.response?.data?.message) {
          this.submitContactFormError = error.response.data.message;
        }
      }
    },

    handleMsoShopSelected(shop) {
      this.selectedAccount = shop;
    },

    async handleMsoContactFormSubmitted({ question, firstName }) {
      this.displayedScreen = "webchat";

      this.conversationMessages.push({
        slug: generateUniqueSlug(),
        content: question,
      });

      await new Promise((resolve) => setTimeout(() => resolve(true), 700));

      this.appendReceivedMessage({
        slug: generateUniqueSlug(),
        content: `Thanks ${firstName}! We'll text you back soon with a response.`,
      });
    },
  },
};
</script>
