<template>
  <div class="chat-body is-mso-form-wrapper">
    <transition name="fade-faster" mode="out-in">
      <find-shop-by-zipcode
        v-if="displayedScreen === 'find-nearest-shop'"
        :company-accounts="companyAccounts"
        @shop-found="handleShopSelected"
        @show-manually-select-shop-screen="
          displayedScreen = 'manually-select-shop'
        "
      />

      <manually-select-shop
        v-else-if="displayedScreen === 'manually-select-shop'"
        :company-accounts="companyAccounts"
        @show-find-nearest-shop-screen="displayedScreen = 'find-nearest-shop'"
        @preferred-shop-selected="handleShopSelected"
      />

      <submit-question-form
        v-else-if="displayedScreen === 'submit-question-form'"
        :selected-account="selectedAccount"
        @go-back="handleSubmitQuestionFormGoBack"
        @question-submitted="handleQuestionSubmitted"
      />
    </transition>
  </div>
</template>

<script>
import FindShopByZipcode from "./FindShopByZipcode";
import ManuallySelectShop from "./ManuallySelectShop";
import SubmitQuestionForm from "./SubmitQuestionForm";

export default {
  name: "MsoContactForm",
  components: {
    FindShopByZipcode,
    ManuallySelectShop,
    SubmitQuestionForm,
  },
  props: {
    companyAccounts: {
      type: Array,
      required: true,
    },
    selectedAccount: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      // find-nearest-shop, manually-select-shop, submit-question-form
      displayedScreen: "find-nearest-shop",
    };
  },
  methods: {
    handleShopSelected(shop) {
      this.displayedScreen = "submit-question-form";
      this.$emit("shop-selected", shop);
    },
    handleSubmitQuestionFormGoBack() {
      this.displayedScreen = "find-nearest-shop";
    },
    handleQuestionSubmitted(payload) {
      this.$emit("question-submitted", payload);
    },
  },
};
</script>

<style></style>
