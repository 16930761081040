<template>
  <div
    class="chat-message-wrapper with-margin-bottom"
    :class="{
      received: message.received,
      sent: !message.received,
    }"
  >
    <div v-if="message.received" class="jasmine-icon-wrapper">
      <jasmine-icon />
    </div>
    <div v-if="message.typing" class="typing-wrapper">
      <div class="typing">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div
      v-else-if="message.slug === 'customer-contact-form'"
      class="chat-message is-contact-form-wrapper"
    >
      <transition name="fade" mode="out-in">
        <div
          v-if="!message.submitted"
          class="leave-customer-question-form-wrapper"
          key="conact_form"
        >
          <el-form label-position="top">
            <el-form-item label="Phone">
              <el-input
                type="tel"
                inputmode="tel"
                placeholder="(555) 555-5555"
                oninput="this.value = this.value.replace(/[^\d+]+/g, '').replace(/(\..*)\./g, '$1')"
                v-model="phone"
              />
            </el-form-item>

            <el-form-item label="Name">
              <el-input v-model="name" placeholder="Alan McWrench" />
            </el-form-item>

            <el-form-item class="no-mb" label="Message">
              <el-input
                type="textarea"
                placeholder="Enter your question..."
                rows="3"
                v-model="messageContent"
              />
            </el-form-item>

            <transition name="fade">
              <p v-if="submitContactFormError" class="error-text">
                {{ submitContactFormError }}
              </p>
            </transition>

            <el-button
              type="primary"
              :disabled="
                !name || !phone || !messageContent || submittingContactForm
              "
              @click.prevent="handleSubmitContactForm"
            >
              Confirm
            </el-button>

            <div class="toc-text-wrapper">
              <span>
                By submitting, you authorize {{ accountName }} to send text
                messages to the number you provided. Messaging/data rates may
                apply. Reply STOP to opt-out at any time. Our
              </span>
              <a
                class="text-smaller"
                href="https://www.shopgenie.io/terms"
                target="_blank"
              >
                Terms & Conditions.
              </a>
            </div>
          </el-form>
        </div>

        <div v-else key="form_submitted" class="contact-form-submitted-wrapper">
          <div class="d-flex">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6107 9.55389C18.0208 9.14384 18.0208 8.47901 17.6107 8.06896C17.2007 7.65891 16.5359 7.65891 16.1258 8.06896L10.3057 13.889L8.06522 11.6485C7.65517 11.2385 6.99035 11.2385 6.5803 11.6485C6.17025 12.0586 6.17025 12.7234 6.5803 13.1335L9.56327 16.1164C9.97332 16.5265 10.6381 16.5265 11.0482 16.1164L17.6107 9.55389Z"
                fill="#29A341"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0955 1.49951C6.24377 1.49951 1.5 6.24329 1.5 12.095C1.5 17.9468 6.24377 22.6905 12.0955 22.6905C17.9473 22.6905 22.691 17.9468 22.691 12.095C22.691 6.24329 17.9473 1.49951 12.0955 1.49951ZM3.6 12.095C3.6 7.40308 7.40357 3.59951 12.0955 3.59951C16.7875 3.59951 20.591 7.40308 20.591 12.095C20.591 16.787 16.7875 20.5905 12.0955 20.5905C7.40357 20.5905 3.6 16.787 3.6 12.095Z"
                fill="#29A341"
              />
            </svg>
          </div>
          <div class="info-text">Submitted</div>
        </div>
      </transition>
    </div>

    <div v-else class="chat-message">
      {{ message.content }}
    </div>
  </div>
</template>

<script>
import JasmineIcon from "./icons/JasmineIcon";

export default {
  name: "ChatMessage",
  components: {
    JasmineIcon,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    submittingContactForm: {
      type: Boolean,
      default: false,
    },
    submitContactFormError: {
      type: String,
      required: false,
    },
    accountName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      name: "",
      phone: "",
      messageContent: "",
    };
  },
  methods: {
    handleSubmitContactForm() {
      this.$emit("submit-contact-form", {
        name: this.name,
        phone: this.phone,
        messageContent: this.messageContent,
      });
    },
  },
};
</script>
