<template>
  <div class="chat-body">
    <transition-group name="fade">
      <chat-message
        v-for="message in conversationMessages"
        :key="message.slug"
        :account-name="accountName"
        :message="message"
        :submitting-contact-form="submittingContactForm"
        :submit-contact-form-error="submitContactFormError"
        @submit-contact-form="
          (payload) => $emit('submit-contact-form', payload)
        "
      />
    </transition-group>

    <transition name="fade">
      <div v-if="showCustomerActions" class="customer-actions-wrapper">
        <customer-action
          v-for="action in customerActions"
          :key="action.slug"
          :action="action"
          @click.native="$emit('customer-action-click', action)"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import ChatMessage from "./ChatMessage";
import CustomerAction from "./CustomerAction";

export default {
  name: "Webchat",
  components: {
    ChatMessage,
    CustomerAction,
  },
  props: {
    accountName: {
      type: String,
      required: false,
    },
    conversationMessages: {
      type: Array,
      default: () => [],
    },
    showCustomerActions: {
      type: Boolean,
      default: false,
    },
    customerActions: {
      type: Array,
      default: () => [],
    },
    submittingContactForm: {
      type: Boolean,
      default: false,
    },
    submitContactFormError: {
      type: String,
      required: false,
    },
  },
};
</script>
