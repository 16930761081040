<template>
  <div class="manually-select-shop-screen">
    <location-icon />

    <div class="text-header-3">Search a preferred shop</div>

    <el-select filterable v-model="selectedAccount" placeholder="Search shop">
      <el-option
        v-for="account in companyAccounts"
        :key="account.slug"
        :label="account.name"
        :value="account.slug"
        :style="{ maxWidth: elOptionMaxWidth }"
      >
        <span class="text-header-4">{{ account.name }}</span>
        <span class="text-body-5">{{ account.company_address }}</span>
      </el-option>
    </el-select>

    <a
      href="#"
      class="link-with-icon"
      @click.prevent="$emit('show-find-nearest-shop-screen')"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.89815 5.1449C7.44652 5.1449 6.26974 6.32168 6.26974 7.77331C6.26974 9.22494 7.44652 10.4017 8.89815 10.4017C10.3498 10.4017 11.5266 9.22494 11.5266 7.77331C11.5266 6.32168 10.3498 5.1449 8.89815 5.1449ZM7.84475 7.77331C7.84475 7.19152 8.31637 6.7199 8.89815 6.7199C9.47994 6.7199 9.95156 7.19152 9.95156 7.77331C9.95156 8.35509 9.47994 8.82672 8.89815 8.82672C8.31637 8.82672 7.84475 8.35509 7.84475 7.77331Z"
          fill="#337AFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.46039 16.4047C8.46039 16.4047 8.4608 16.405 8.89763 15.7498L8.46039 16.4047C8.72492 16.5811 9.06994 16.5814 9.33446 16.405L8.89763 15.7498C9.33446 16.405 9.33446 16.405 9.33446 16.405L9.33685 16.4034L9.34125 16.4005L9.35594 16.3905L9.40803 16.3548C9.4525 16.324 9.51607 16.2793 9.59601 16.2216C9.7558 16.1062 9.98147 15.9381 10.2511 15.7234C10.7891 15.295 11.5086 14.6758 12.2307 13.9135C13.6427 12.4231 15.2079 10.2368 15.2079 7.77251C15.2079 6.09893 14.543 4.4939 13.3596 3.3105C12.1762 2.12711 10.5712 1.46228 8.89763 1.46228C7.22405 1.46228 5.61902 2.12711 4.43563 3.3105C3.25223 4.4939 2.5874 6.09893 2.5874 7.77251C2.5874 10.2368 4.15257 12.4231 5.56458 13.9135C6.28669 14.6758 7.00612 15.295 7.54415 15.7234C7.81379 15.9381 8.03946 16.1062 8.19925 16.2216C8.27919 16.2793 8.34276 16.324 8.38723 16.3548L8.43932 16.3905L8.45401 16.4005L8.45841 16.4034L8.46039 16.4047ZM5.54932 4.4242C6.43735 3.53617 7.64177 3.03728 8.89763 3.03728C10.1535 3.03728 11.3579 3.53617 12.2459 4.4242C13.134 5.31222 13.6329 6.51665 13.6329 7.77251C13.6329 9.60371 12.4367 11.406 11.0873 12.8303C10.4287 13.5255 9.76749 14.0952 9.27 14.4913C9.13145 14.6017 9.00609 14.6981 8.89763 14.7797C8.78917 14.6981 8.66381 14.6017 8.52526 14.4913C8.02777 14.0952 7.36653 13.5255 6.70795 12.8303C5.3586 11.406 4.1624 9.60371 4.1624 7.77251C4.1624 6.51665 4.66129 5.31222 5.54932 4.4242Z"
          fill="#337AFF"
        />
      </svg>
      <span>Find a shop near me</span>
    </a>
  </div>
</template>

<script>
import LocationIcon from "../icons/LocationIcon";

export default {
  name: "ManuallySelectShop",
  components: {
    LocationIcon,
  },
  props: {
    companyAccounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedAccount: null,
      elOptionMaxWidth: "380px",
    };
  },
  mounted() {
    setTimeout(() => {
      const elSelectDropdownMinWidth = Number(
        document
          .querySelector(".el-select-dropdown.el-popper")
          ?.style?.minWidth.split("px")
          .at(0)
      );
      this.elOptionMaxWidth = `${elSelectDropdownMinWidth - 20}px`;
    }, 150);
  },
  watch: {
    selectedAccount(value) {
      setTimeout(() => {
        const selectedAccount = this.companyAccounts.find(
          ({ slug }) => slug === value
        );
        this.$emit("preferred-shop-selected", selectedAccount);
      }, 200);
    },
  },
};
</script>

<style></style>
