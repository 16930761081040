export const INITIAL_WEBCHAT_CUSTOMER_ACTIONS = [
  {
    slug: "looking-around",
    text: "Just looking around!",
    predefinedAnswer: "No problem! Have a great day ⚡️",
    visible: true,
  },
  {
    slug: "leave-a-question",
    text: "I've got a question for the shop.",
    predefinedAnswer:
      "Great! Would you mind sharing your contact info so we can text you?",
    visible: true,
  },
  {
    slug: "book-appointment",
    text: "I'd like to book an appointment.",
    visible: true,
  },
];

export const MIN_ZIPCODE_LENGTH = 4;
