import httpClient from "@/axios";

export default {
  get: {
    show: (slug) => {
      return httpClient.get(`/companies/${slug}`);
    },
    accounts: (slug) => {
      return httpClient.get(`/companies/${slug}/accounts`);
    },
    nearestShopByZipcode: (slug, zipcode) => {
      return httpClient.get(
        `companies/${slug}/accounts/nearest?zip_code=${zipcode}`
      );
    },
  },
};
