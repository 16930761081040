<template>
  <div class="chat-header">
    <div class="header-text">
      {{ title }}
    </div>

    <a href="#" @click.prevent="closeChat" class="close-app-btn">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.4914 14.395C2.18386 14.7026 2.18386 15.2012 2.4914 15.5087C2.79893 15.8163 3.29755 15.8163 3.60509 15.5087L9.00012 10.1137L14.3952 15.5087C14.7027 15.8163 15.2013 15.8163 15.5088 15.5087C15.8164 15.2012 15.8164 14.7026 15.5088 14.395L10.1138 9L15.5088 3.60497C15.8164 3.29743 15.8164 2.79881 15.5088 2.49127C15.2013 2.18374 14.7027 2.18374 14.3952 2.49127L9.00012 7.88631L3.60509 2.49127C3.29755 2.18374 2.79893 2.18374 2.4914 2.49127C2.18386 2.79881 2.18386 3.29743 2.4914 3.60497L7.88643 9L2.4914 14.395Z"
          fill="#2D3139"
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "ChatHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeChat() {
      window.parent.postMessage({ name: "jasmine.closeChat" }, "*");
    },
  },
};
</script>
