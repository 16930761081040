import axios from 'axios';

const searchParams = new URLSearchParams(window.location.search);
const customApiUrl = searchParams.has('custom_api_url') ? searchParams.get('custom_api_url') : null;
let baseURL = customApiUrl || process.env.VUE_APP_API_URL;

// Append "public" if baseURL does not end with a slash
if (!baseURL.endsWith('/')) {
    baseURL += '/public';
} else {
    baseURL += 'public';
}


const httpClient = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

export default httpClient;
