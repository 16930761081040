<template>
  <div class="mso-submit-question-form">
    <div class="selected-shop-info">
      <location-icon />

      <div class="shop-details-wrapper">
        <div class="text-header-4">{{ selectedAccount?.name }}</div>
        <div class="text-body-5">{{ selectedAccount?.company_address }}</div>
        <div class="text-body-5">{{ selectedAccount?.company_phone }}</div>
      </div>
    </div>

    <div class="form-group">
      <div class="text-header-4">How can we help?</div>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="Ask us anything and we'll text you back."
        v-model="question"
      />
    </div>

    <div class="form-group">
      <div class="text-header-4">Your name</div>
      <el-input placeholder="Enter your name" v-model="name" />
    </div>

    <div class="form-group">
      <div class="text-header-4">Phone</div>
      <el-input
        type="tel"
        inputmode="tel"
        placeholder="Enter phone"
        v-model="phone"
        oninput="this.value = this.value.replace(/[^\d+]+/g, '').replace(/(\..*)\./g, '$1')"
      />

      <transition name="fade">
        <div v-if="error" class="error-text no-margin">
          {{ error }}
        </div>
      </transition>
    </div>

    <div class="text-body-5 add-extra-padding-bottom">
      By submitting, you agree to receive SMS and email notifications about your
      appointment from {{ selectedAccount?.name }}. Messaging and data rates may
      apply. Reply STOP to opt-out at any time. Our
      <a
        class="text-smaller"
        href="https://www.shopgenie.io/terms"
        target="_blank"
      >
        Terms & Conditions</a
      >.
    </div>

    <div class="bottom-fixed-actions-wrapper stretch-buttons">
      <button class="btn secondary" @click.prevent="$emit('go-back')">
        Back
      </button>
      <button
        class="btn primary full-width"
        :disabled="!isFormFilled || loading"
        @click.prevent="submitForm"
      >
        {{ loading ? "Sending..." : "Send Message" }}
      </button>
    </div>
  </div>
</template>

<script>
import LocationIcon from "../icons/LocationIcon";

import CustomerService from "@/services/CustomerService";

export default {
  name: "SubmitQuestionForm",
  components: {
    LocationIcon,
  },
  props: {
    selectedAccount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      question: "",
      name: "",
      phone: "",
    };
  },
  computed: {
    isFormFilled() {
      return this.question && this.name.length >= 2 && this.phone.length >= 7;
    },
  },
  methods: {
    async submitForm() {
      if (!this.selectedAccount) return;

      try {
        this.error = null;
        this.loading = true;

        await CustomerService.post.contact(
          this.selectedAccount.slug,
          this.name,
          this.phone,
          this.question
        );

        this.loading = false;
        this.$emit("question-submitted", {
          question: this.question,
          firstName: this.name.split(" ").filter(Boolean).at(0),
        });
      } catch (error) {
        this.error =
          error?.response?.status === 422 && error?.response?.data?.message
            ? error.response.data.message
            : "Something went wrong";
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
