import Vue from "vue";
import App from "./App.vue";
import axios from "@/axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";

import "./assets/reset.scss";
import "./assets/transitions.scss";
import "./assets/styles.scss";

require("dotenv").config();

Vue.config.productionTip = false;

Vue.prototype.$http_client = axios;

Vue.use(ElementUI, { locale });

new Vue({
  render: (h) => h(App),
}).$mount("#app");
