<template>
  <div class="customer-action">
    <div class="customer-action-text">
      {{ action.text }}
    </div>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.80636 2.43305C3.03552 2.2343 3.36185 2.19316 3.63317 2.32882L15.6332 8.32882C15.8873 8.45587 16.0478 8.71557 16.0478 8.99965C16.0478 9.28372 15.8873 9.54342 15.6332 9.67047L3.63317 15.6705C3.36185 15.8061 3.03552 15.765 2.80636 15.5662C2.5772 15.3675 2.49032 15.0503 2.58625 14.7625L4.02927 10.4334C4.16539 10.0251 4.54753 9.74963 4.97796 9.74963H10.1721C10.5863 9.74963 10.9221 9.41385 10.9221 8.99963C10.9221 8.58542 10.5863 8.24963 10.1721 8.24963H4.97795C4.54752 8.24963 4.16538 7.9742 4.02926 7.56586L2.58625 3.23681C2.49032 2.94904 2.5772 2.63181 2.80636 2.43305Z"
        fill="#0047CC"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
