<template>
  <div class="enter-zipcode-screen">
    <location-icon />

    <div class="zipcode-screen-info-text-wrapper">
      <div class="text-header-3">Enter zipcode</div>
      <div class="text-body-4">
        We'll only use this to find a shop closest to you.
      </div>
    </div>

    <el-input v-model="zipcode" placeholder="Enter zipcode" />

    <a href="#" @click.prevent="$emit('show-manually-select-shop-screen')">
      I have a preferred shop
    </a>

    <transition name="fade-faster">
      <div v-if="showActionButton" class="bottom-fixed-actions-wrapper">
        <button
          class="btn primary full-width"
          @click.prevent="findNearestShop"
          :disabled="loading"
        >
          {{ loading ? "Loading..." : "Find shop" }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import LocationIcon from "@/components/icons/LocationIcon";

import { MIN_ZIPCODE_LENGTH } from "@/config";

import CompanyService from "@/services/CompanyService";

export default {
  name: "FindShopByZipcode",
  components: {
    LocationIcon,
  },
  props: {
    companyAccounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      zipcode: "",
      loading: false,
    };
  },
  computed: {
    showActionButton() {
      return this.zipcode.length >= MIN_ZIPCODE_LENGTH;
    },
    shopCompanySlug() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("shop_slug") || "";
    },
  },
  methods: {
    async findNearestShop() {
      try {
        this.loading = true;

        const {
          data: { data: nearestShop },
        } = await CompanyService.get.nearestShopByZipcode(
          this.shopCompanySlug,
          this.zipcode
        );

        this.$emit("shop-found", nearestShop);
        this.loading = false;
      } catch (error) {
        console.log('Something went wrong');
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
