<template>
  <div class="chat-footer">
    <div class="powered-by-shopgenie-wrapper">
      <span class="text">Powered by</span>
      <shopgenie-logo-svg />
    </div>
  </div>
</template>

<script>
import ShopgenieLogoSvg from "./icons/ShopgenieLogoSvg";

export default {
  name: "ChatFooter",
  components: {
    ShopgenieLogoSvg,
  },
};
</script>
