import httpClient from "@/axios";

export default {
  post: {
    contact: (accountSlug, name, phone, message) => {
      return httpClient.post(`/accounts/${accountSlug}/customers/contact`, {
        customer_name: name,
        customer_phone: phone,
        customer_message: message,
      });
    },
  },
};
