<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="-0.000244141" width="40" height="40" rx="8" fill="#E5EEFF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3668 24.7124V27.3528H18.3668V24.7124H16.3668ZM14.3668 23.0621C14.3668 22.8798 14.516 22.7321 14.7001 22.7321H20.0334C20.2175 22.7321 20.3668 22.8798 20.3668 23.0621V29.0031C20.3668 29.1854 20.2175 29.3331 20.0334 29.3331H14.7001C14.516 29.3331 14.3668 29.1854 14.3668 29.0031V23.0621Z"
      fill="#003699"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.3334 20C29.3334 18.5272 28.1395 17.3333 26.6667 17.3333H24.0001V13.3333C24.0001 11.8605 22.8062 10.6666 21.3334 10.6666H13.3334C11.8607 10.6666 10.6667 11.8605 10.6667 13.3333V28C10.6667 28.7363 11.2637 29.3333 12.0001 29.3333H28.0001C28.7365 29.3333 29.3334 28.7363 29.3334 28V20ZM23.9939 19.3333V27.3333H27.3334V20C27.3334 19.6318 27.0349 19.3333 26.6667 19.3333H23.9939ZM21.9939 27.3333H12.6667V13.3333C12.6667 12.9651 12.9652 12.6666 13.3334 12.6666H21.3334C21.7016 12.6666 22.0001 12.9651 22.0001 13.3333V17.3256H21.9939V27.3333Z"
      fill="#003699"
    />
    <path
      d="M14.3666 15.0312C14.3666 14.8471 14.5159 14.6979 14.7 14.6979H16.0333C16.2174 14.6979 16.3666 14.8471 16.3666 15.0312V16.3645C16.3666 16.5486 16.2174 16.6979 16.0333 16.6979H14.7C14.5159 16.6979 14.3666 16.5486 14.3666 16.3645V15.0312Z"
      fill="#003699"
    />
    <path
      d="M14.3666 19.0312C14.3666 18.8471 14.5159 18.6979 14.7 18.6979H16.0333C16.2174 18.6979 16.3666 18.8471 16.3666 19.0312V20.3645C16.3666 20.5486 16.2174 20.6979 16.0333 20.6979H14.7C14.5159 20.6979 14.3666 20.5486 14.3666 20.3645V19.0312Z"
      fill="#003699"
    />
    <path
      d="M18.3668 15.096C18.3668 14.9119 18.516 14.7627 18.7001 14.7627H20.0334C20.2175 14.7627 20.3668 14.9119 20.3668 15.096V16.4293C20.3668 16.6134 20.2175 16.7627 20.0334 16.7627H18.7001C18.516 16.7627 18.3668 16.6134 18.3668 16.4293V15.096Z"
      fill="#003699"
    />
    <path
      d="M18.3668 19.0312C18.3668 18.8471 18.516 18.6979 18.7001 18.6979H20.0334C20.2175 18.6979 20.3668 18.8471 20.3668 19.0312V20.3645C20.3668 20.5486 20.2175 20.6979 20.0334 20.6979H18.7001C18.516 20.6979 18.3668 20.5486 18.3668 20.3645V19.0312Z"
      fill="#003699"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
